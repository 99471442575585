import React, { useEffect, useMemo, useRef } from "react";
import Select, { createFilter } from "react-select";

export function RichSelect({
	options,
	value,
	placeholder,
	onChange = val => {},
	minWidth = 120,
	fontSize = 13,
	isSearchableFilter = false,
	controlShouldRenderValue = false,
	paddingY = 2,
	paddingX = 0,
	showNum = "",
	disabled = false,
	...props
}) {
	const onValueChange = v => {
		if (Array.isArray(v)) {
			onChange(v.map(item => item.value));
			return;
		}
		onChange(v?.value);
	};
	const currentValue = Array.isArray(value)
		? options.filter(opt => value.indexOf(opt.value) !== -1)
		: options.find(opt => opt.value === value);

	const styles = useMemo(
		() => ({
			control: (base, state) => {
				return {
					...base,
					transition: "all .2s ease",
					minHeight: 20,
					paddingLeft: paddingX,
					paddingRight: paddingX,
					paddingTop: paddingY,
					paddingBottom: paddingY,
					borderRadius: 6,
					minWidth,
					fontSize,
					cursor: state.isDisabled ? "not-allowed" : "pointer",
					border: state.menuIsOpen
						? "solid 1px #64748b"
						: "solid 1px rgb(209, 213, 219)",
					boxShadow: state.menuIsOpen
						? "0 0 0 1px #64748b"
						: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
					backgroundColor: state.isDisabled ? "#f3f4f6" : "#fff",
					border: state.isDisabled ? "solid 1px #E8EAED" : "solid 1px #D0D5DB",
					"&:hover": {
						border: state.menuIsOpen
							? "solid 1px #64748b"
							: "solid 1px rgb(209, 213, 219)",
					},
				};
			},
			multiValue: provided => ({
				...provided,
				borderRadius: "8px",
				margin: "3px 5px",
				padding: "0px 2px",
			}),
			input: provided => ({
				...provided,
				margin: "0px",
			}),
			indicatorsContainer: provided => ({
				...provided,
				height: "auto",
				paddingTop: 0,
				paddingBottom: 0,
				display: "flex",
				borderRight: "none",
			}),
			indicatorSeparator: provided => ({
				display: "none",
			}),
			dropdownIndicator: provided => ({
				...provided,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 4,
				paddingRight: 4,
				transform: "scale(0.75)",
			}),
			menu: provided => ({
				...provided,
				minWidth: 80,
				zIndex: 999,
			}),
			option: (base, { isDisabled, isFocused }) => ({
				...base,
				cursor: isDisabled ? "not-allowed" : "pointer",
				height: "auto",
				minHeight: 0,
				padding: "6px 12px",
				fontWeight: 400,
				fontSize,
				background: isDisabled
					? "#f5f5f5"
					: isFocused
					? "rgba(0,0,0,0.025)"
					: "none",
				color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
				"&:active": {
					background: isDisabled
						? "#f5f5f5"
						: isFocused
						? "rgba(0,0,0,0.025)"
						: "none",
					color: isDisabled ? "#D1D5DB" : "#1F2937",
				},
			}),
		}),
		[paddingX, paddingY, minWidth, fontSize, disabled],
	);
	const pickerRef = useRef(null);

	useEffect(() => {
		if (pickerRef.current !== null) {
			pickerRef.current.inputRef.readOnly = true;
		}
	}, [pickerRef]);

	function sortByPropertyAndAlphabet(prop) {
		return function (a, b) {
			const aValue = a[prop] !== undefined ? a[prop] : 0;
			const bValue = b[prop] !== undefined ? b[prop] : 0;

			// Prioritize options with prop not undefined and greater than 0
			if (aValue > 0) {
				if (bValue > 0) {
					// Both have prop > 0, sort alphabetically
					return a.label.localeCompare(b.label);
				} else {
					// Only a has prop > 0, prioritize a
					return -1;
				}
			} else {
				if (bValue > 0) {
					// Only b has prop > 0, prioritize b
					return 1;
				} else {
					// Both don't have prop > 0, sort alphabetically
					return a.label.localeCompare(b.label);
				}
			}
		};
	}

	return (
		<div className="rich-select">
			{
				<Select
					isClearable={false}
					controlShouldRenderValue={controlShouldRenderValue}
					ref={!!isSearchableFilter ? null : pickerRef}
					isDisabled={disabled}
					options={
						!!showNum
							? options.sort(sortByPropertyAndAlphabet(showNum))
							: options
					}
					value={currentValue}
					onChange={onValueChange}
					placeholder={placeholder ?? "בחר..."}
					styles={styles}
					noOptionsMessage={() => "לא נמצאו פריטים."}
					filterOption={(option, searchText) => {
						return (
							option?.label
								?.toLowerCase()
								.includes(searchText?.toLowerCase()) ||
							(option?.data?.eng_name &&
								option.data.eng_name
									.toLowerCase()
									.includes(searchText?.toLowerCase()))
						);
					}}
					formatOptionLabel={option => {
						if (!showNum) {
							return option.label;
						} else {
							let sideInfo = option[showNum];
							return (
								<span>
									{option.label} ({!!sideInfo ? sideInfo : 0})
								</span>
							);
						}
					}}
					{...props}
				/>
			}
		</div>
	);
}
