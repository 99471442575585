import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { useForm } from "react-hook-form";
import { AuthContext } from "components/contexts/AuthContext";
import { toastMessage } from "util/common";

const useUserAddresses = () => {
	const api = useApi();
	const { claims, authenticated } = useContext(AuthContext);
	const userId = claims?.id;
	const [userAddresses, setUserAddresses] = useState([]);
	const [addressToEdit, setAddressToEdit] = useState(null);
	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [addressToDelete, setAddressToDelete] = useState(null);
	const [addressLoading, setAddressLoading] = useState(false);
	const [error, setError] = useState(null);

	const addressForm = useForm();

	useEffect(() => {
		if (!authenticated) {
			return;
		}
		loadUserAddresses();
	}, [authenticated]);

	const loadUserAddresses = async () => {
		try {
			setAddressLoading(true);
			const res = await api.getUserAddresses();
			if (res.ok) {
				const sortedAddresses = res.data.data.sort((a, b) => {
					if (b.main !== a.main) {
						return b.main - a.main; // Sort by main first
					}
					return a.id - b.id; // Then sort by id
				});
				setUserAddresses(sortedAddresses);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			setError(error);
		} finally {
			setAddressLoading(false);
		}
	};

	const createUserAddress = async props => {
		const params = props ?? {
			...addressForm.getValues(),
			city_id: addressForm.getValues("city").id,
		};
		try {
			setAddressLoading(true);
			const res = await api.createUserAddress(params);
			if (res.ok) {
				await loadUserAddresses(userId);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.error(error);
			setError(error);
		} finally {
			setAddressLoading(false);
		}
	};

	const deleteUserAddress = async addressId => {
		const id = addressId ?? addressToDelete.id;
		try {
			setAddressLoading(true);
			const res = await api.deleteUserAddress(id);
			if (res.ok) {
				await loadUserAddresses(userId);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.error(error);
			setError(error);
		} finally {
			setAddressLoading(false);
			setIsDeleteModalOpen(false);
			setAddressToDelete(null);
		}
	};

	const deleteAddress = addressId => {
		const address = userAddresses.find(address => address.id === addressId);
		if (address.main) {
			toastMessage("לא ניתן למחוק כתובת בית", "error");
			return;
		}
		setIsDeleteModalOpen(true);
		setAddressToDelete(address);
	};

	const editUserAddress = async params => {
		try {
			setAddressLoading(true);
			if (params.main === false) {
				delete params.main;
			}
			const res = await api.editUserAddress(params.id, params);
			if (res.ok) {
				await loadUserAddresses(userId);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.error(error);
			setError(error);
		} finally {
			setAddressLoading(false);
		}
	};
	const editAddress = address => {
		setAddressToEdit(address);
		addressForm.setValue("city", {
			id: address.city_id,
			label: address.city_heb_name,
		});
		addressForm.setValue("street_name", address.street_name);
		addressForm.setValue("street_num", address.street_num);
		addressForm.setValue("entrance", address.entrance);
		addressForm.setValue("floor", address.floor);
		addressForm.setValue("apartment", address.apartment);
		addressForm.setValue("main", address.main);
		addressForm.setValue("id", address.id);
		setIsAddressModalOpen(true);
	};

	const saveAddress = async () => {
		addressForm.handleSubmit(async params => {
			params = {
				...params,
				city_id: params.city.id,
			};
			delete params.city;
			if (!!params.id) {
				await editUserAddress(params);
			} else {
				await createUserAddress(params);
			}
			setIsAddressModalOpen(false);
			addressForm.reset();
		})();
	};

	const createAddress = () => {
		addressForm.reset();
		setIsAddressModalOpen(true);
	};

	return {
		addressLoading,
		error,
		userAddresses,
		loadUserAddresses,
		createUserAddress,
		deleteUserAddress,
		editUserAddress,
		addressToEdit,
		isAddressModalOpen,
		setIsAddressModalOpen,
		isDeleteModalOpen,
		setIsDeleteModalOpen,
		editAddress,
		addressForm,
		saveAddress,
		deleteAddress,
		createAddress,
		addressToDelete,
	};
};

export default useUserAddresses;
